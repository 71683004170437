@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

/* Reset default margin and padding, set box-sizing to border-box */
* {
  @apply m-0 p-0 box-border;
}

/* Set body dimensions */
body {
  @apply w-screen h-full;
}

.loader-overlay {
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
  /* MUI modals default to z-index 1300. Setting the loader at 1301 to overlay the modal. */
  @apply fixed top-0 left-0 w-full h-full flex items-center justify-center z-[1301] bg-opacity-50;
}

.scaling-button {
  transition:
    transform 0.8s ease-in-out,
    background-color 0.3s ease-in-out;
  transform-origin: left;
}

.scaling-button:hover {
  @apply transform scale-x-[1.3] bg-[#28b3db];
}

@keyframes grow {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}

.success-icon svg {
  animation: grow 1s ease-in-out;
}

.comment-textarea:focus {
  resize: none;
  border-radius: 5px;
  outline: none !important;
  border: 2px solid #6fc73d;
}

/* add the grabbing cursor when the item is being dragged */
.grabbing * {
  cursor: grabbing !important;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.overflow-hidden {
  overflow: hidden;
}

.task-date-picker-popper {
  z-index: 101 !important;
}

.custom-datepicker {
  width: 100% !important;
  display: block !important;
  max-width: 100% !important;
  padding: 16.5px 14px;
}

/* truncating table cell in case of long text */
.table-cell-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
